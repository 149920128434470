<template>
  <div>
    <el-form :model="form"
             label-width="60px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="届">
            <el-select v-model="form.graduated"
                       style="width:100%"
                       clearable
                       filterable
                       @change="getClass"
                       placeholder="请选择">
              <el-option v-for="item in graduatedList"
                         :key="item.graduated_id"
                         :label="item.year_desc"
                         :value="item.graduated_id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="年级">
            <el-select v-model="form.grade_id"
                       style="width:100%"
                       @change="getClass"
                       placeholder="请选择">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="科目">
            <el-select v-model="form.subject_id"
                       style="width:100%"
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="add">初始化</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <EditForm ref="EditFormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import EditForm from './components/editForm.vue'
export default {
  data () {
    return {
      form: {
        grade_id: '',
        subject_id: '',
      },
      graduatedList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      gradeList: [],
      subjectList: [],
      pageName: 'cz_grade_subject_textbook_admin',
      tableColumns: [
        // { prop: 'id', align: 'center', label: '编号', width: '80', showToopic: false },
        { prop: 'year_desc', align: 'center', label: '届', width: '', showToopic: false },
        { prop: 'grade_name', align: 'center', label: '年级', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'textbook_name', align: 'center', label: '教材', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            // { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
    }
  },
  components: {
    Form, EditForm
  },
  mounted () {
    this.setTablesColums()
    this.getGradeList()
    this.getJieList()
  },
  methods: {
    getJieList () {
      this.$http({
        url: '/api/v2/public/graduated_lst',
        method: 'get'
      }).then(res => {
        this.graduatedList = res.data
      })
    },
    getGradeList () {
      this.$http({
        url: '/api/v2/public/grade_lst',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/textbook/grade_book',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    editRow (row) {
      var form = {
        id: row.id,
        textbook_id: row.textbook_id
      }
      console.log(form)
      this.$refs.EditFormRef.form = form
      this.$refs.EditFormRef.getTextbookList()
      this.$refs.EditFormRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/special/class_del',
          method: 'post',
          data: {
            id: row.id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    add () {
      let form = {
        graduated: ''
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    getClass () {
      this.form.subject_id = ''
      this.subjectList = []
      if (this.form.grade_id == '') return
      this.$http({
        url: '/api/v2/public/get_subject',
        method: 'get',
        params: {
          grade_id: this.form.grade_id
        }
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
